import { BaseReportFields } from '@modules/missions/keaz/types'
import { apiCall } from '@api/resources/apiCall'
import { skipToken } from '@reduxjs/toolkit/query'
import { AgentumReportWithRelations } from '@api/generated'
import { formatFullName } from '@modules/missions/keaz/components/formatFullName'

interface TasksTableCellAssigneesProps {
  task: AgentumReportWithRelations
}

export function TasksTableCellAssignees({ task }: TasksTableCellAssigneesProps) {
  const { data: assigneeDetails = [] } = apiCall.useUsersControllerFindQuery(
    (task[BaseReportFields.assignees] as string[])?.length > 0
      ? [
          {
            where: {
              _id: { inq: task[BaseReportFields.assignees] as string[] }
            }
          }
        ]
      : skipToken
  )

  return (
    <>
      {assigneeDetails.map((user) => (
        <div key={user._id}>{formatFullName(user.firstname, user.lastname)}</div>
      ))}
    </>
  )
}
