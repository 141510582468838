import {
  BaseReportFields,
  ElectricalSteps,
  Groups,
  MechanicalSteps
} from '@modules/missions/keaz/types'
import { AgentumReportWithRelations } from '@api/generated'
import { labels } from '@modules/missions/keaz/units/reports/labels'
import { config } from '@/settings'
import { PROJECT } from '@modules/missions/keaz/const'
import { secondsSince } from '@modules/missions/keaz/utils/time'

export const getStepTitle = (step: AgentumReportWithRelations) => {
  let title = ''
  if (step) {
    switch (step[BaseReportFields.group]) {
      case Groups.mechanical:
        title = labels[Groups.mechanical][step[BaseReportFields.stepType] as MechanicalSteps]
        break
      case Groups.electrical:
        title = labels[Groups.electrical][step[BaseReportFields.stepType] as ElectricalSteps]
        break
    }
  }

  return `${step[BaseReportFields.stepNumber] as string}. ${title}`
}

export const getTaskImageByName = (mission: string, name: string, project = PROJECT) =>
  `${config.apiUrl}/mission/${mission}/project/${project}/files/${name}.jpeg`

export const getStepTimeSpent = (step: AgentumReportWithRelations) => {
  return secondsSince(
    step[BaseReportFields.timestampStart] as number | undefined,
    step[BaseReportFields.timestampEnd] as number | undefined
  )
}

export const getStepTimePlan = (step: AgentumReportWithRelations) => {
  return ((step[BaseReportFields.timeNorm] as number[]) || []).reduce(
    (acc: number, sec: number) => acc + sec,
    0
  )
}
