import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  MechanicalStepPressureAndPumpCheck,
  MechanicalStepPressureAndPumpCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { TextField } from '@smwb/summer-ui/dist/connects/rff'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

export interface PressureAndPumpCheckProps {
  compact: boolean
  step: MechanicalStepPressureAndPumpCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function PressureAndPumpCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: PressureAndPumpCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepPressureAndPumpCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <TextField
              name={MechanicalStepPressureAndPumpCheckFields.manometerReadings}
              type='number'
              label='Показания манометра'
              fullWidth={true}
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepPressureAndPumpCheck>
              compact={compact}
              title='Фото дисплея манометра'
              fieldName={MechanicalStepPressureAndPumpCheckFields.manometerDisplayPhoto}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepPressureAndPumpCheckFields.deviations}
          commentFieldName={MechanicalStepPressureAndPumpCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
