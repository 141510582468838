import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import {
  MechanicalStepFineOilFilterCheck,
  MechanicalStepFineOilFilterCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

export interface FineOilFilterCheckProps {
  compact: boolean
  step: MechanicalStepFineOilFilterCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function FineOilFilterCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: FineOilFilterCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepFineOilFilterCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepFineOilFilterCheckFields.fineOilFilterReplacementRequired}
              label='Необходимо заменить масляный фильтр тонкой очистки'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepFineOilFilterCheck>
              compact={compact}
              title='Фото показаний манометра без замены фильтра тонкой очистки'
              fieldName={
                MechanicalStepFineOilFilterCheckFields.manometerReadingBeforeReplacementPhoto
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepFineOilFilterCheck>
              compact={compact}
              title='Фото показаний манометра после замены фильтра тонкой очистки'
              fieldName={
                MechanicalStepFineOilFilterCheckFields.manometerReadingAfterReplacementPhoto
              }
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepFineOilFilterCheckFields.deviations}
          commentFieldName={MechanicalStepFineOilFilterCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
