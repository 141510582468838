import style from './groupCard.module.less'
import { Title } from '@/components/ui/typography/title'
import { Text } from '@/components/ui/typography/text'
import { Chip, ChipVariant } from '@modules/missions/keaz/task/components/chip'
import { Button } from '@smwb/summer-ui'
import { BaseReportFields, Groups, GroupStatus, ReportSubtype } from '@modules/missions/keaz/types'
import { MouseEvent, useMemo } from 'react'
import cn from 'classnames'
import { secondsToHHMMSS } from '@modules/missions/keaz/utils/time'
import { useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { PROJECT } from '@modules/missions/keaz/const'
import { skipToken } from '@reduxjs/toolkit/query'
import { getStepsSummaryStatus, isCompletedStep } from '@modules/missions/keaz/utils/steps'
import { getStepTimePlan, getStepTimeSpent } from '@modules/missions/keaz/utils/step'
import { AgentumReportWithRelations } from '@api/generated'

interface GroupCardProps {
  className?: string
  task: AgentumReportWithRelations
  group: Groups
  onButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

export function GroupCard({ className, task, group, onButtonClick }: GroupCardProps) {
  const { missionId } = useParams()

  const { data: steps = [] } = apiCall.useAgentumReportControllerFindQuery(
    missionId
      ? [
          missionId,
          PROJECT,
          {
            where: {
              [BaseReportFields.subtype]: ReportSubtype.step,
              [BaseReportFields.taskId]: task[BaseReportFields.origin],
              [BaseReportFields.group]: group,
              [BaseReportFields.deleted]: false
            }
          }
        ]
      : skipToken
  )

  const groupStatus = getStepsSummaryStatus(steps)

  let statusChipVariant: ChipVariant = 'default'
  switch (groupStatus) {
    case GroupStatus.signed:
      statusChipVariant = 'gray2'
      break
    case GroupStatus.default:
      statusChipVariant = 'gray'
      break
    case GroupStatus.approved:
      // case GroupStatus.sendedToERP:
      statusChipVariant = 'success'
      break
    case GroupStatus.inProgress:
      statusChipVariant = 'warning'
      break
    case GroupStatus.rejected:
      statusChipVariant = 'orange'
      break
  }

  const completedSteps = useMemo(
    () => steps.reduce((count: number, step) => count + +isCompletedStep(step), 0),
    [steps]
  )

  const timeSpent = useMemo(
    () => steps.reduce((count: number, step) => count + getStepTimeSpent(step), 0),
    [steps]
  )

  const timePlan = useMemo(
    () => steps.reduce((count: number, step) => count + getStepTimePlan(step), 0),
    [steps]
  )

  return (
    <div className={cn(className, style.group)}>
      <div className={style.groupTitleWrapper}>
        <Title level={1} className={style.title}>
          {group}
        </Title>
        {steps.length > 0 && <Chip variant={statusChipVariant}>{groupStatus}</Chip>}
      </div>

      <div className={style.descriptionWrapper}>
        <Text className={style.description} tag='div' size='l'>
          Затрачено времени: {secondsToHHMMSS(timeSpent)}
        </Text>

        <Text className={style.description} tag='div' size='l'>
          Норматив времени: {secondsToHHMMSS(timePlan)}
        </Text>

        <Text className={style.description} tag='div' size='l'>
          Шагов выполнено: {completedSteps}/{steps.length}
        </Text>
      </div>

      <Button fullWidth className={style.groupButton} onClick={onButtonClick}>
        Перейти
      </Button>
    </div>
  )
}
