import { PhotoUrl } from '@modules/missions/keaz/step/components/photoView'

export enum StepStatus {
  default = 'Не обследован',
  inProgress = 'В работе',
  waitingApproval = 'Ожидает проверки',
  approved = 'Принят',
  rejected = 'Отклонен'
}

export enum ReportSubtype {
  task = 'task',
  step = 'step'
}

export enum ReportType {
  unsigned = 'unsigned',
  signed = 'signed'
}

export enum BaseReportFields {
  id = '_id',
  origin = 'origin',
  deleted = 'deleted',
  timestamp = 'timestamp',
  timestampStart = 'timestamp_start',
  timestampEnd = 'timestamp_end',
  type = 'type',
  synced = 'synced',
  subtype = 'subtype',
  name = 'number',
  status = 'status',
  statusReason = 'status_reason',
  stepType = 'stepType',
  taskId = 'taskId',
  group = 'group',
  equipment = 'equipment',
  equipmentType = 'equipmentType',
  timeNorm = 'timeNorm',
  assigner = 'assigner',
  userId = 'userId',
  assignees = 'assignees',
  stepNumber = 'stepNumber'
}

export interface BaseReport {
  [BaseReportFields.timestamp]: number
  [BaseReportFields.timestampStart]?: number
  [BaseReportFields.timestampEnd]?: number
  [BaseReportFields.id]: string
  [BaseReportFields.synced]?: boolean
  [BaseReportFields.origin]: string
  [BaseReportFields.deleted]: boolean
  [BaseReportFields.type]: ReportType
  [BaseReportFields.subtype]: ReportSubtype
  [BaseReportFields.name]: string
  [BaseReportFields.status]: StepStatus
  [BaseReportFields.statusReason]: string
  [BaseReportFields.stepType]?: MechanicalSteps | ElectricalSteps
  [BaseReportFields.taskId]?: string
  [BaseReportFields.group]?: Groups
  [BaseReportFields.equipment]?: string
  [BaseReportFields.equipmentType]?: string
  [BaseReportFields.timeNorm]?: number[]
  [BaseReportFields.assigner]?: Asigner
  [BaseReportFields.userId]?: string
  [BaseReportFields.assignees]?: string[]
  [BaseReportFields.stepNumber]?: number
}

export enum AsignerFields {
  id = 'id',
  firstname = 'firstname',
  lastname = 'lastname'
}

export interface Asigner {
  [AsignerFields.id]: string
  [AsignerFields.firstname]: string
  [AsignerFields.lastname]: string
}

export enum TaskReportFields {
  performers = 'creator_firstname',
  completedReports = 'completedReports',
  totalReports = 'totalReports'
}

export enum Groups {
  mechanical = 'механическая',
  electrical = 'электрическая'
}

export interface TaskReport extends BaseReport {
  [TaskReportFields.performers]: string
  [TaskReportFields.completedReports]: number
  [TaskReportFields.totalReports]: number
}

export enum GroupStatus {
  default = 'Не обследован',
  inProgress = 'В работе',
  signed = 'Ожидает проверки',
  rejected = 'Отклонен',
  approved = 'Принят',
  sendedToERP = 'Отправлено в ERP...'
}

export enum GroupFields {
  title = 'title',
  timeSpent = 'timeSpent',
  timePlan = 'timePlan',
  status = 'status',
  completedSteps = 'completedSteps',
  totalSteps = 'totalSteps'
}

export interface Group {
  [GroupFields.title]: string
  [GroupFields.timeSpent]: number
  [GroupFields.timePlan]: number
  [GroupFields.status]: GroupStatus
  [GroupFields.completedSteps]: number
  [GroupFields.totalSteps]: number
}

export enum MechanicalSteps {
  machineIdentification = 'machineIdentification',
  hydraulicConnectionCheck = 'hydraulicConnectionCheck',
  pressureAndPumpCheck = 'pressureAndPumpCheck',
  safetyCheck = 'safetyCheck',
  limitSwitchCheck = 'limitSwitchCheck',
  injectionUnitLeakCheck = 'injectionUnitLeakCheck',
  clampingUnitLeakCheck = 'clampingUnitLeakCheck',
  ejectionUnitLeakCheck = 'ejectionUnitLeakCheck',
  fasteningCheck = 'fasteningCheck',
  coolingSystemCheck = 'coolingSystemCheck',
  coolingCircuitFlush = 'coolingCircuitFlush',
  oilChangeAndFilterCleaning = 'oilChangeAndFilterCleaning',
  fineOilFilterCheck = 'fineOilFilterCheck',
  lubricationSystemCheck = 'lubricationSystemCheck',
  equipmentCleaning = 'equipmentCleaning'
}

export enum ElectricalSteps {
  machineIdentification = 'machineIdentification',
  currentCheckUnderLoad = 'currentCheckUnderLoad',
  noiseAndVibrationCheck = 'noiseAndVibrationCheck',
  cabinetAndMotorCleaning = 'cabinetAndMotorCleaning',
  heaterZonesCheck = 'heaterZonesCheck',
  electricalConnectionsCheck = 'electricalConnectionsCheck',
  injectionMoldingMachineMovementsCheck = 'injectionMoldingMachineMovementsCheck'
}

export enum StepFields {
  title = 'title',
  status = 'status'
}

export enum MechanicalStepMachineIdentificationFields {
  // Сканирования QR-кода
  qrCode = 'qrCode'
}

export enum MechanicalStepHydraulicConnectionCheckFields {
  rearToggleMechanismCheckPoint1 = 'rearToggleMechanismCheckPoint1',
  rearToggleMechanismPhotoPoint1 = 'rearToggleMechanismPhotoPoint1',
  rearToggleMechanismCheckPoint2 = 'rearToggleMechanismCheckPoint2',
  rearToggleMechanismPhotoPoint2 = 'rearToggleMechanismPhotoPoint2',
  rearPlasticizationUnitCheckPoint1 = 'rearPlasticizationUnitCheckPoint1',
  rearPlasticizationUnitPhotoPoint1 = 'rearPlasticizationUnitPhotoPoint1',
  rearPlasticizationUnitCheckPoint2 = 'rearPlasticizationUnitCheckPoint2',
  rearPlasticizationUnitPhotoPoint2 = 'rearPlasticizationUnitPhotoPoint2',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepPressureAndPumpCheckFields {
  manometerReadings = 'manometerReadings',
  manometerDisplayPhoto = 'manometerDisplayPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepSafetyCheckFields {
  frontClampingUnitDoorChecked = 'frontClampingUnitDoorChecked',
  rearClampingUnitDoorChecked = 'rearClampingUnitDoorChecked',
  nozzleGuardChecked = 'nozzleGuardChecked',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepLimitSwitchCheckFields {
  // Проверка концевиков основного защитного экрана проведена
  mainScreenLimitSwitchCheck = 'mainScreenLimitSwitchCheck',
  // Фото дисплея с результатом проверки концевиков основного защитного экрана
  mainScreenLimitSwitchDisplayPhoto = 'mainScreenLimitSwitchDisplayPhoto',
  // Проверка концевиков экрана сопла проведена
  nozzleScreenLimitSwitchCheck = 'nozzleScreenLimitSwitchCheck',
  // Фото дисплея с результатом проверки концевиков экрана
  nozzleScreenLimitSwitchDisplayPhoto = 'nozzleScreenLimitSwitchDisplayPhoto',
  // Проверка направляющих проведена
  guideRailsCheck = 'guideRailsCheck',
  // Фото дисплея с результатом проверки направляющих
  guideRailsDisplayPhoto = 'guideRailsDisplayPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepInjectionUnitLeakCheckFields {
  // Проверка на течь масла цилиндров узлов впрыска проведена
  injectionUnitLeakCheck = 'injectionUnitLeakCheck',
  // Фото цилиндров узлов впрыска
  injectionUnitCylindersPhoto = 'injectionUnitCylindersPhoto',
  // Фото цилиндров узлов впрыска после замены уплотнений
  injectionUnitSealsReplacedPhoto = 'injectionUnitSealsReplacedPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepClampingUnitLeakCheckFields {
  // Проверка на течь масла цилиндров узлов смыкания проведена
  clampingUnitLeakCheck = 'clampingUnitLeakCheck',
  // Фото цилиндров узлов смыкания
  clampingUnitCylindersPhoto = 'clampingUnitCylindersPhoto',
  // Фото цилиндров узлов смыкания после замены уплотнений
  clampingUnitSealsReplacedPhoto = 'clampingUnitSealsReplacedPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepEjectionUnitLeakCheckFields {
  // Проверка на течь масла цилиндров узлов выталкивания проведена
  ejectionUnitLeakCheck = 'ejectionUnitLeakCheck',
  // Фото цилиндров узлов выталкивания
  ejectionUnitCylindersPhoto = 'ejectionUnitCylindersPhoto',
  // Фото цилиндров узлов выталкивания после замены уплотнений
  ejectionUnitSealsReplacedPhoto = 'ejectionUnitSealsReplacedPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepFasteningCheckFields {
  // Крепеж зацепа хвостовика формы
  tailstockFormEngagementFastening = 'tailstockFormEngagementFastening',
  // Крепеж масляных экранов
  oilScreensFastening = 'oilScreensFastening',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepCoolingSystemCheckFields {
  // Проверка на течь соединения шлангов в точке №1 проведена
  hoseConnectionLeakCheckPoint1 = 'hoseConnectionLeakCheckPoint1',
  // Фото соединения шлангов в точке №1
  hoseConnectionPhotoPoint1 = 'hoseConnectionPhotoPoint1',
  // Фото соединения шлангов в точке №1 после замены
  hoseConnectionReplacementPhotoPoint1 = 'hoseConnectionReplacementPhotoPoint1',
  // Проверка на течь соединения шлангов в точке №2 проведена
  hoseConnectionLeakCheckPoint2 = 'hoseConnectionLeakCheckPoint2',
  // Фото соединения шлангов в точке №2
  hoseConnectionPhotoPoint2 = 'hoseConnectionPhotoPoint2',
  // Фото соединения шлангов в точке №2 после замены
  hoseConnectionReplacementPhotoPoint2 = 'hoseConnectionReplacementPhotoPoint2',
  // Проверка на течь соединения шлангов в точке №3 проведена
  hoseConnectionLeakCheckPoint3 = 'hoseConnectionLeakCheckPoint3',
  // Фото соединения шлангов в точке №3
  hoseConnectionPhotoPoint3 = 'hoseConnectionPhotoPoint3',
  // Фото соединения шлангов в точке №3 после замены
  hoseConnectionReplacementPhotoPoint3 = 'hoseConnectionReplacementPhotoPoint3',
  // Проверка на течь соединения шлангов в точке №4 проведена
  hoseConnectionLeakCheckPoint4 = 'hoseConnectionLeakCheckPoint4',
  // Фото соединения шлангов в точке №4
  hoseConnectionPhotoPoint4 = 'hoseConnectionPhotoPoint4',
  // Фото соединения шлангов в точке №4 после замены
  hoseConnectionReplacementPhotoPoint4 = 'hoseConnectionReplacementPhotoPoint4',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepCoolingCircuitFlushFields {
  // Фото подключенной установки промывки к контуру охлаждения гидравлического масла
  flushingSystemConnectionCoolingCircuitPhoto = 'flushingSystemConnectionCoolingCircuitPhoto',
  // Фото подключенной установки промывки к кольцу охлаждения на загрузочном отверстии
  flushingSystemConnectionCoolingRingPhoto = 'flushingSystemConnectionCoolingRingPhoto',
  // Поток охлаждающей жидкости
  coolantFlow = 'coolantFlow',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepOilChangeAndFilterCleaningFields {
  // Необходимо заменить масло
  oilReplacementRequired = 'oilReplacementRequired',
  // Фото состояния масла
  oilConditionPhoto = 'oilConditionPhoto',
  // Фото состояния масла после замены
  oilConditionAfterReplacementPhoto = 'oilConditionAfterReplacementPhoto',
  // Необходимо очистить всасывающий фильтр
  suctionFilterCleaningRequired = 'suctionFilterCleaningRequired',
  // Фото всасывающего фильтра до очистки
  suctionFilterBeforeCleaningPhoto = 'suctionFilterBeforeCleaningPhoto',
  // Фото всасывающего фильтра после очистки
  suctionFilterAfterCleaningPhoto = 'suctionFilterAfterCleaningPhoto',
  // Необходимо очистить заливной фильтр
  fillingFilterCleaningRequired = 'fillingFilterCleaningRequired',
  // Фото заливного фильтра до очистки
  fillingFilterBeforeCleaningPhoto = 'fillingFilterBeforeCleaningPhoto',
  // Фото заливного фильтра после очистки
  fillingFilterAfterCleaningPhoto = 'fillingFilterAfterCleaningPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepFineOilFilterCheckFields {
  // Необходимо заменить масляный фильтр тонкой очистки
  fineOilFilterReplacementRequired = 'fineOilFilterReplacementRequired',
  // Фото показаний манометра без замены фильтра тонкой очистки
  manometerReadingBeforeReplacementPhoto = 'manometerReadingBeforeReplacementPhoto',
  // Фото показаний манометра после замены фильтра тонкой очистки
  manometerReadingAfterReplacementPhoto = 'manometerReadingAfterReplacementPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepLubricationSystemCheckFields {
  // Проверка на повреждение или обрыв трубки в точке №1 проведена
  tubeDamageCheckPoint1 = 'tubeDamageCheckPoint1',
  // Фото в точке №1
  tubePhotoPoint1 = 'tubePhotoPoint1',
  // Проверка на повреждение или обрыв трубки в точке №2 проведена
  tubeDamageCheckPoint2 = 'tubeDamageCheckPoint2',
  // Фото в точке №2
  tubePhotoPoint2 = 'tubePhotoPoint2',
  // Заполнение смазки произведено
  lubricantRefillCompleted = 'lubricantRefillCompleted',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum MechanicalStepEquipmentCleaningFields {
  // Фото очищенного масляного поддона
  cleanedOilPanPhoto = 'cleanedOilPanPhoto',
  // Фото очищенных направляющих колено-рычажного механизма
  cleanedToggleGuidesPhoto = 'cleanedToggleGuidesPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum ElectricalStepMachineIdentificationFields {
  // Сканирования QR-кода
  qrCode = 'qrCode'
}

export enum ElectricalStepCurrentCheckUnderLoadFields {
  // Показания тока для точки №1
  currentReadingPoint1 = 'currentReadingPoint1',
  // Фото показаний с дисплея токовых клещей для точки №1
  currentDisplayPhotoPoint1 = 'currentDisplayPhotoPoint1',
  // Показания тока для точки №2
  currentReadingPoint2 = 'currentReadingPoint2',
  // Фото показаний с дисплея токовых клещей для точки №2
  currentDisplayPhotoPoint2 = 'currentDisplayPhotoPoint2',
  // Показания тока для точки №3
  currentReadingPoint3 = 'currentReadingPoint3',
  // Фото показаний с дисплея токовых клещей для точки №3
  currentDisplayPhotoPoint3 = 'currentDisplayPhotoPoint3',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum ElectricalStepNoiseAndVibrationCheckFields {
  // Проверка на нестандартные шумы в двигателе
  abnormalNoiseCheck = 'abnormalNoiseCheck',
  // Проверка на наличие вибрации двигателя
  vibrationCheck = 'vibrationCheck',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum ElectricalStepCabinetAndMotorCleaningFields {
  // Фото очищенного шкафа
  cabinetCleaningPhoto = 'cabinetCleaningPhoto',
  // Фото очищенного двигателя
  motorCleaningPhoto = 'motorCleaningPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum ElectricalStepHeaterZonesCheckFields {
  // Показания тока для точки №1
  currentReadingPoint1 = 'currentReadingPoint1',
  // Фото показаний с дисплея токовых клещей для точки №1
  currentDisplayPhotoPoint1 = 'currentDisplayPhotoPoint1',
  // Показания тока для точки №2
  currentReadingPoint2 = 'currentReadingPoint2',
  // Фото показаний с дисплея токовых клещей для точки №2
  currentDisplayPhotoPoint2 = 'currentDisplayPhotoPoint2',
  // Показания тока для точки №3
  currentReadingPoint3 = 'currentReadingPoint3',
  // Фото показаний с дисплея токовых клещей для точки №3
  currentDisplayPhotoPoint3 = 'currentDisplayPhotoPoint3',
  // Показания тока для точки №4
  currentReadingPoint4 = 'currentReadingPoint4',
  // Фото показаний с дисплея токовых клещей для точки №4
  currentDisplayPhotoPoint4 = 'currentDisplayPhotoPoint4',
  // Показания тока для точки №5
  currentReadingPoint5 = 'currentReadingPoint5',
  // Фото показаний с дисплея токовых клещей для точки №5
  currentDisplayPhotoPoint5 = 'currentDisplayPhotoPoint5',
  // Показания тока для точки №6
  currentReadingPoint6 = 'currentReadingPoint6',
  // Фото показаний с дисплея токовых клещей для точки №6
  currentDisplayPhotoPoint6 = 'currentDisplayPhotoPoint6',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum ElectricalStepElectricalConnectionsCheckFields {
  // Проверка затяжки автоматов
  circuitBreakerTightnessCheck = 'circuitBreakerTightnessCheck',
  // Проверка затяжки контактора на нагрев цилиндра
  contactorTightnessCheck = 'contactorTightnessCheck',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export enum ElectricalStepInjectionMoldingMachineMovementsCheckFields {
  // Проверка хода подвижной плиты
  movablePlateMovementCheck = 'movablePlateMovementCheck',
  // Фото дисплея с результатом проверки хода подвижной плиты
  movablePlateMovementDisplayPhoto = 'movablePlateMovementDisplayPhoto',
  // Проверка хода узла впрыска
  injectionUnitMovementCheck = 'injectionUnitMovementCheck',
  // Фото дисплея с результатом проверки хода узла впрыска
  injectionUnitMovementDisplayPhoto = 'injectionUnitMovementDisplayPhoto',
  // Проверка хода толкателей
  ejectorMovementCheck = 'ejectorMovementCheck',
  // Фото дисплея с результатом проверки хода толкателей
  ejectorMovementDisplayPhoto = 'ejectorMovementDisplayPhoto',
  // Проверка хода шнека
  screwMovementCheck = 'screwMovementCheck',
  // Фото дисплея с результатом проверки хода шнека
  screwMovementDisplayPhoto = 'screwMovementDisplayPhoto',
  deviations = 'deviations',
  deviationsComment = 'deviationsComment'
}

export interface Step extends BaseReport {
  [StepFields.title]: string
  [BaseReportFields.group]: Groups
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MechanicalStep extends Step {}

export interface MechanicalStepMachineIdentification extends MechanicalStep {
  [MechanicalStepMachineIdentificationFields.qrCode]?: string
}

export interface MechanicalStepHydraulicConnectionCheck extends MechanicalStep {
  [MechanicalStepHydraulicConnectionCheckFields.rearToggleMechanismCheckPoint1]?: boolean
  [MechanicalStepHydraulicConnectionCheckFields.rearToggleMechanismPhotoPoint1]?: PhotoUrl[]
  [MechanicalStepHydraulicConnectionCheckFields.rearToggleMechanismCheckPoint2]?: boolean
  [MechanicalStepHydraulicConnectionCheckFields.rearToggleMechanismPhotoPoint2]?: PhotoUrl[]
  [MechanicalStepHydraulicConnectionCheckFields.rearPlasticizationUnitCheckPoint1]?: boolean
  [MechanicalStepHydraulicConnectionCheckFields.rearPlasticizationUnitPhotoPoint1]?: PhotoUrl[]
  [MechanicalStepHydraulicConnectionCheckFields.rearPlasticizationUnitCheckPoint2]?: boolean
  [MechanicalStepHydraulicConnectionCheckFields.rearPlasticizationUnitPhotoPoint2]?: PhotoUrl[]
  [MechanicalStepHydraulicConnectionCheckFields.deviations]?: boolean
  [MechanicalStepHydraulicConnectionCheckFields.deviationsComment]?: string
}

export interface MechanicalStepPressureAndPumpCheck extends MechanicalStep {
  [MechanicalStepPressureAndPumpCheckFields.manometerReadings]?: string
  [MechanicalStepPressureAndPumpCheckFields.manometerDisplayPhoto]?: PhotoUrl[]
  [MechanicalStepPressureAndPumpCheckFields.deviations]?: boolean
  [MechanicalStepPressureAndPumpCheckFields.deviationsComment]?: string
}

export interface MechanicalStepSafetyCheck extends MechanicalStep {
  [MechanicalStepSafetyCheckFields.frontClampingUnitDoorChecked]?: boolean
  [MechanicalStepSafetyCheckFields.rearClampingUnitDoorChecked]?: boolean
  [MechanicalStepSafetyCheckFields.nozzleGuardChecked]?: boolean
  [MechanicalStepSafetyCheckFields.deviations]?: boolean
  [MechanicalStepSafetyCheckFields.deviationsComment]?: string
}

export interface MechanicalStepLimitSwitchCheck extends MechanicalStep {
  [MechanicalStepLimitSwitchCheckFields.mainScreenLimitSwitchCheck]?: boolean
  [MechanicalStepLimitSwitchCheckFields.mainScreenLimitSwitchDisplayPhoto]?: PhotoUrl[]
  [MechanicalStepLimitSwitchCheckFields.nozzleScreenLimitSwitchCheck]?: boolean
  [MechanicalStepLimitSwitchCheckFields.nozzleScreenLimitSwitchDisplayPhoto]?: PhotoUrl[]
  [MechanicalStepLimitSwitchCheckFields.guideRailsCheck]?: boolean
  [MechanicalStepLimitSwitchCheckFields.guideRailsDisplayPhoto]?: PhotoUrl[]
  [MechanicalStepLimitSwitchCheckFields.deviations]?: boolean
  [MechanicalStepLimitSwitchCheckFields.deviationsComment]?: string
}

export interface MechanicalStepInjectionUnitLeakCheck extends MechanicalStep {
  [MechanicalStepInjectionUnitLeakCheckFields.injectionUnitLeakCheck]?: boolean
  [MechanicalStepInjectionUnitLeakCheckFields.injectionUnitCylindersPhoto]?: PhotoUrl[]
  [MechanicalStepInjectionUnitLeakCheckFields.injectionUnitSealsReplacedPhoto]?: PhotoUrl[]
  [MechanicalStepInjectionUnitLeakCheckFields.deviations]?: boolean
  [MechanicalStepInjectionUnitLeakCheckFields.deviationsComment]?: string
}

export interface MechanicalStepClampingUnitLeakCheck extends MechanicalStep {
  [MechanicalStepClampingUnitLeakCheckFields.clampingUnitLeakCheck]?: boolean
  [MechanicalStepClampingUnitLeakCheckFields.clampingUnitCylindersPhoto]?: PhotoUrl[]
  [MechanicalStepClampingUnitLeakCheckFields.clampingUnitSealsReplacedPhoto]?: PhotoUrl[]
  [MechanicalStepClampingUnitLeakCheckFields.deviations]?: boolean
  [MechanicalStepClampingUnitLeakCheckFields.deviationsComment]?: string
}

export interface MechanicalStepEjectionUnitLeakCheck extends MechanicalStep {
  [MechanicalStepEjectionUnitLeakCheckFields.ejectionUnitLeakCheck]?: boolean
  [MechanicalStepEjectionUnitLeakCheckFields.ejectionUnitCylindersPhoto]?: PhotoUrl[]
  [MechanicalStepEjectionUnitLeakCheckFields.ejectionUnitSealsReplacedPhoto]?: PhotoUrl[]
  [MechanicalStepEjectionUnitLeakCheckFields.deviations]?: boolean
  [MechanicalStepEjectionUnitLeakCheckFields.deviationsComment]?: string
}

export interface MechanicalStepFasteningCheck extends MechanicalStep {
  [MechanicalStepFasteningCheckFields.tailstockFormEngagementFastening]?: boolean
  [MechanicalStepFasteningCheckFields.oilScreensFastening]?: boolean
  [MechanicalStepFasteningCheckFields.deviations]?: boolean
  [MechanicalStepFasteningCheckFields.deviationsComment]?: string
}

export interface MechanicalStepCoolingSystemCheck extends MechanicalStep {
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionLeakCheckPoint1]?: boolean
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionPhotoPoint1]?: PhotoUrl[]
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionReplacementPhotoPoint1]?: PhotoUrl[]
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionLeakCheckPoint2]?: boolean
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionPhotoPoint2]?: PhotoUrl[]
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionReplacementPhotoPoint2]?: PhotoUrl[]
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionLeakCheckPoint3]?: boolean
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionPhotoPoint3]?: PhotoUrl[]
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionReplacementPhotoPoint3]?: PhotoUrl[]
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionLeakCheckPoint4]?: boolean
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionPhotoPoint4]?: PhotoUrl[]
  [MechanicalStepCoolingSystemCheckFields.hoseConnectionReplacementPhotoPoint4]?: PhotoUrl[]
  [MechanicalStepCoolingSystemCheckFields.deviations]?: boolean
  [MechanicalStepCoolingSystemCheckFields.deviationsComment]?: string
}

export enum MechanicalStepCoolantFlow {
  // Проток воды отсутствует
  noWaterFlow = 'noWaterFlow',
  // Проток воды присутствует, но недостаточен
  insufficientWaterFlow = 'insufficientWaterFlow',
  // Проток воды достаточный
  sufficientWaterFlow = 'sufficientWaterFlow'
}

export interface MechanicalStepCoolingCircuitFlush extends MechanicalStep {
  [MechanicalStepCoolingCircuitFlushFields.flushingSystemConnectionCoolingCircuitPhoto]?: PhotoUrl[]
  [MechanicalStepCoolingCircuitFlushFields.flushingSystemConnectionCoolingRingPhoto]?: PhotoUrl[]
  [MechanicalStepCoolingCircuitFlushFields.coolantFlow]?: MechanicalStepCoolantFlow
  [MechanicalStepCoolingCircuitFlushFields.deviations]?: boolean
  [MechanicalStepCoolingCircuitFlushFields.deviationsComment]?: string
}

export interface MechanicalStepOilChangeAndFilterCleaning extends MechanicalStep {
  [MechanicalStepOilChangeAndFilterCleaningFields.oilReplacementRequired]?: boolean
  [MechanicalStepOilChangeAndFilterCleaningFields.oilConditionPhoto]?: PhotoUrl[]
  [MechanicalStepOilChangeAndFilterCleaningFields.oilConditionAfterReplacementPhoto]?: PhotoUrl[]
  [MechanicalStepOilChangeAndFilterCleaningFields.suctionFilterCleaningRequired]?: boolean
  [MechanicalStepOilChangeAndFilterCleaningFields.suctionFilterBeforeCleaningPhoto]?: PhotoUrl[]
  [MechanicalStepOilChangeAndFilterCleaningFields.suctionFilterAfterCleaningPhoto]?: PhotoUrl[]
  [MechanicalStepOilChangeAndFilterCleaningFields.fillingFilterCleaningRequired]?: boolean
  [MechanicalStepOilChangeAndFilterCleaningFields.fillingFilterBeforeCleaningPhoto]?: PhotoUrl[]
  [MechanicalStepOilChangeAndFilterCleaningFields.fillingFilterAfterCleaningPhoto]?: PhotoUrl[]
  [MechanicalStepOilChangeAndFilterCleaningFields.deviations]?: boolean
  [MechanicalStepOilChangeAndFilterCleaningFields.deviationsComment]?: string
}

export interface MechanicalStepFineOilFilterCheck extends MechanicalStep {
  [MechanicalStepFineOilFilterCheckFields.fineOilFilterReplacementRequired]?: boolean
  [MechanicalStepFineOilFilterCheckFields.manometerReadingBeforeReplacementPhoto]?: PhotoUrl[]
  [MechanicalStepFineOilFilterCheckFields.manometerReadingAfterReplacementPhoto]?: PhotoUrl[]
  [MechanicalStepFineOilFilterCheckFields.deviations]?: boolean
  [MechanicalStepFineOilFilterCheckFields.deviationsComment]?: string
}

export interface MechanicalStepLubricationSystemCheck extends MechanicalStep {
  [MechanicalStepLubricationSystemCheckFields.tubeDamageCheckPoint1]?: boolean
  [MechanicalStepLubricationSystemCheckFields.tubePhotoPoint1]?: PhotoUrl[]
  [MechanicalStepLubricationSystemCheckFields.tubeDamageCheckPoint2]?: boolean
  [MechanicalStepLubricationSystemCheckFields.tubePhotoPoint2]?: PhotoUrl[]
  [MechanicalStepLubricationSystemCheckFields.lubricantRefillCompleted]?: boolean
  [MechanicalStepLubricationSystemCheckFields.deviations]?: boolean
  [MechanicalStepLubricationSystemCheckFields.deviationsComment]?: string
}

export interface MechanicalStepEquipmentCleaning extends MechanicalStep {
  [MechanicalStepEquipmentCleaningFields.cleanedOilPanPhoto]?: PhotoUrl[]
  [MechanicalStepEquipmentCleaningFields.cleanedToggleGuidesPhoto]?: PhotoUrl[]
  [MechanicalStepEquipmentCleaningFields.deviations]?: boolean
  [MechanicalStepEquipmentCleaningFields.deviationsComment]?: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ElectricalStep extends Step {}

export interface ElectricalStepMachineIdentification extends ElectricalStep {
  [MechanicalStepMachineIdentificationFields.qrCode]?: string
}

export interface ElectricalStepCurrentCheckUnderLoad extends ElectricalStep {
  [ElectricalStepCurrentCheckUnderLoadFields.currentReadingPoint1]?: string
  [ElectricalStepCurrentCheckUnderLoadFields.currentDisplayPhotoPoint1]?: PhotoUrl[]
  [ElectricalStepCurrentCheckUnderLoadFields.currentReadingPoint2]?: string
  [ElectricalStepCurrentCheckUnderLoadFields.currentDisplayPhotoPoint2]?: PhotoUrl[]
  [ElectricalStepCurrentCheckUnderLoadFields.currentReadingPoint3]?: string
  [ElectricalStepCurrentCheckUnderLoadFields.currentDisplayPhotoPoint3]?: PhotoUrl[]
  [ElectricalStepCurrentCheckUnderLoadFields.deviations]?: boolean
  [ElectricalStepCurrentCheckUnderLoadFields.deviationsComment]?: string
}

export interface ElectricalStepNoiseAndVibrationCheck extends ElectricalStep {
  [ElectricalStepNoiseAndVibrationCheckFields.abnormalNoiseCheck]?: boolean
  [ElectricalStepNoiseAndVibrationCheckFields.vibrationCheck]?: boolean
  [ElectricalStepNoiseAndVibrationCheckFields.deviations]?: boolean
  [ElectricalStepNoiseAndVibrationCheckFields.deviationsComment]?: string
}

export interface ElectricalStepCabinetAndMotorCleaning extends ElectricalStep {
  [ElectricalStepCabinetAndMotorCleaningFields.cabinetCleaningPhoto]?: PhotoUrl[]
  [ElectricalStepCabinetAndMotorCleaningFields.motorCleaningPhoto]?: PhotoUrl[]
  [ElectricalStepCabinetAndMotorCleaningFields.deviations]?: boolean
  [ElectricalStepCabinetAndMotorCleaningFields.deviationsComment]?: string
}

export interface ElectricalStepHeaterZonesCheck extends ElectricalStep {
  [ElectricalStepHeaterZonesCheckFields.currentReadingPoint1]?: string
  [ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint1]?: PhotoUrl[]
  [ElectricalStepHeaterZonesCheckFields.currentReadingPoint2]?: string
  [ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint2]?: PhotoUrl[]
  [ElectricalStepHeaterZonesCheckFields.currentReadingPoint3]?: string
  [ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint3]?: PhotoUrl[]
  [ElectricalStepHeaterZonesCheckFields.currentReadingPoint4]?: string
  [ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint4]?: PhotoUrl[]
  [ElectricalStepHeaterZonesCheckFields.currentReadingPoint5]?: string
  [ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint5]?: PhotoUrl[]
  [ElectricalStepHeaterZonesCheckFields.currentReadingPoint6]?: string
  [ElectricalStepHeaterZonesCheckFields.currentDisplayPhotoPoint6]?: PhotoUrl[]
  [ElectricalStepHeaterZonesCheckFields.deviations]?: boolean
  [ElectricalStepHeaterZonesCheckFields.deviationsComment]?: string
}

export interface ElectricalStepElectricalConnectionsCheck extends ElectricalStep {
  [ElectricalStepElectricalConnectionsCheckFields.circuitBreakerTightnessCheck]?: boolean
  [ElectricalStepElectricalConnectionsCheckFields.contactorTightnessCheck]?: boolean
  [ElectricalStepElectricalConnectionsCheckFields.deviations]?: boolean
  [ElectricalStepElectricalConnectionsCheckFields.deviationsComment]?: string
}

export interface ElectricalStepInjectionMoldingMachineMovementsCheck extends ElectricalStep {
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.movablePlateMovementCheck]?: boolean
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.movablePlateMovementDisplayPhoto]?: PhotoUrl[]
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.injectionUnitMovementCheck]?: boolean
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.injectionUnitMovementDisplayPhoto]?: PhotoUrl[]
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.ejectorMovementCheck]?: boolean
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.ejectorMovementDisplayPhoto]?: PhotoUrl[]
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.screwMovementCheck]?: boolean
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.screwMovementDisplayPhoto]?: PhotoUrl[]
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.deviations]?: boolean
  [ElectricalStepInjectionMoldingMachineMovementsCheckFields.deviationsComment]?: string
}

export interface FormValues {
  [name: string]: string | number
}
