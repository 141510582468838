import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import {
  MechanicalStepClampingUnitLeakCheck,
  MechanicalStepClampingUnitLeakCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'
import React from 'react'
import { ErrorMessage } from '@modules/missions/keaz/step/components/contentBySteps/components/errorMessage'

export interface ClampingUnitLeakCheckProps {
  compact: boolean
  step: MechanicalStepClampingUnitLeakCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function ClampingUnitLeakCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: ClampingUnitLeakCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepClampingUnitLeakCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepClampingUnitLeakCheckFields.clampingUnitLeakCheck}
              label='Проверка на течь масла цилиндров узлов смыкания проведена'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={MechanicalStepClampingUnitLeakCheckFields.clampingUnitLeakCheck}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepClampingUnitLeakCheck>
              compact={compact}
              title='Фото цилиндров узлов смыкания'
              fieldName={MechanicalStepClampingUnitLeakCheckFields.clampingUnitCylindersPhoto}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepClampingUnitLeakCheck>
              compact={compact}
              title='Фото цилиндров узлов смыкания после замены уплотнений'
              fieldName={MechanicalStepClampingUnitLeakCheckFields.clampingUnitSealsReplacedPhoto}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepClampingUnitLeakCheckFields.deviations}
          commentFieldName={MechanicalStepClampingUnitLeakCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
