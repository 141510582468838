import { formatFullName } from '@modules/missions/keaz/components/formatFullName'
import { skipToken } from '@reduxjs/toolkit/query'
import { BaseReportFields } from '@modules/missions/keaz/types'
import { apiCall } from '@api/resources/apiCall'
import { AgentumReportWithRelations } from '@api/generated'

interface TasksTableCellResponsibleUserProps {
  task: AgentumReportWithRelations
}

export function TasksTableCellResponsibleUser({ task }: TasksTableCellResponsibleUserProps) {
  const { data: assigneeDetails = [] } = apiCall.useUsersControllerFindQuery(
    task[BaseReportFields.userId]
      ? [
          {
            where: {
              _id: task[BaseReportFields.userId] as string
            }
          }
        ]
      : skipToken
  )

  return (
    <>
      {assigneeDetails[0]
        ? formatFullName(assigneeDetails[0].firstname, assigneeDetails[0].lastname)
        : 'Нет ответственного'}
    </>
  )
}
