import { TaskReportButton } from '@modules/missions/keaz/components/taskReportButton'
import { AgentumReportWithRelations } from '@api/generated'

interface TasksTableActionsCellProps {
  task: AgentumReportWithRelations
}

export function TasksTableCellActions({ task }: TasksTableActionsCellProps) {
  return <TaskReportButton task={task} />
}
