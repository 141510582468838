import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import {
  MechanicalStepEjectionUnitLeakCheck,
  MechanicalStepEjectionUnitLeakCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'
import { ErrorMessage } from '@modules/missions/keaz/step/components/contentBySteps/components/errorMessage'

export interface EjectionUnitLeakCheckProps {
  compact: boolean
  step: MechanicalStepEjectionUnitLeakCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function EjectionUnitLeakCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: EjectionUnitLeakCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepEjectionUnitLeakCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepEjectionUnitLeakCheckFields.ejectionUnitLeakCheck}
              label='Проверка на течь масла цилиндров узлов выталкивания проведена'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={MechanicalStepEjectionUnitLeakCheckFields.ejectionUnitLeakCheck}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepEjectionUnitLeakCheck>
              compact={compact}
              title='Фото цилиндров узлов выталкивания'
              fieldName={MechanicalStepEjectionUnitLeakCheckFields.ejectionUnitCylindersPhoto}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepEjectionUnitLeakCheck>
              compact={compact}
              title='Фото цилиндров узлов выталкивания после замены уплотнений'
              fieldName={MechanicalStepEjectionUnitLeakCheckFields.ejectionUnitSealsReplacedPhoto}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepEjectionUnitLeakCheckFields.deviations}
          commentFieldName={MechanicalStepEjectionUnitLeakCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
