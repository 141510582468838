import styles from './stepForm.module.less'
import { Step } from '@modules/missions/keaz/types'
import React, { ReactNode } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { Button } from '@smwb/summer-ui'
import { ObjectShape } from 'yup'
import isEmpty from 'lodash/isEmpty'
import { emptyFunction } from '@modules/missions/keaz/const'

export interface EditStepFormProps<T> {
  step: T
  compact: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  children: ReactNode
  validations?: ObjectShape
  isEditableStep: boolean
  onBackClick: () => void
  refetchReports: () => void
}

export function StepForm<T extends Step>({
  step,
  compact,
  setCompact,
  children,
  isEditableStep
}: EditStepFormProps<T>) {
  return (
    <Form<T>
      initialValues={{
        ...step
      }}
      subscription={{ errors: true }}
      onSubmit={emptyFunction}
      render={({ handleSubmit, form, errors }) => {
        return (
          <form
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit}
          >
            {children}

            {compact && isEditableStep && (
              <FormSpy<T> subscription={{ values: true, errors: true }}>
                {({ errors, form }) => {
                  return (
                    <div>
                      <Button
                        variant='outlined'
                        icon='cancel'
                        onClick={() => {
                          setCompact(false)
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          setTimeout(() => form.submit())
                        }}
                        label={'Отмена'}
                        type='button'
                        size='large'
                      />

                      <Button
                        disabled={!isEmpty(errors)}
                        icon='save'
                        onClick={() => {
                          setCompact(true)
                        }}
                        label={'Подписать'}
                        type='button'
                        size='large'
                        className={styles.signReportButton}
                      />
                    </div>
                  )
                }}
              </FormSpy>
            )}

            {process.env.NODE_ENV !== 'production' && !compact && (
              <Button
                label='Подписать'
                size='large'
                onClick={() => {
                  void form.submit()

                  if (isEmpty(errors)) {
                    setCompact((v) => !v)
                  }
                }}
              />
            )}
          </form>
        )
      }}
    />
  )
}
