import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import {
  MechanicalStepLubricationSystemCheck,
  MechanicalStepLubricationSystemCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

import { ErrorMessage } from '@modules/missions/keaz/step/components/contentBySteps/components/errorMessage'

export interface LubricationSystemCheckProps {
  compact: boolean
  step: MechanicalStepLubricationSystemCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function LubricationSystemCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: LubricationSystemCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepLubricationSystemCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepLubricationSystemCheckFields.tubeDamageCheckPoint1}
              label='Проверка на повреждение или обрыв трубки в точке №1 проведена'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepLubricationSystemCheck>
              compact={compact}
              title='Фото в точке №1'
              fieldName={MechanicalStepLubricationSystemCheckFields.tubePhotoPoint1}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepLubricationSystemCheckFields.tubeDamageCheckPoint2}
              label='Проверка на повреждение или обрыв трубки в точке №2 проведена'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepLubricationSystemCheck>
              compact={compact}
              title='Фото в точке №2'
              fieldName={MechanicalStepLubricationSystemCheckFields.tubePhotoPoint2}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepLubricationSystemCheckFields.lubricantRefillCompleted}
              label='Заполнение смазки произведено'
              disabled={compact}
            />

            <ErrorMessage<MechanicalStepLubricationSystemCheck>
              fieldName={MechanicalStepLubricationSystemCheckFields.lubricantRefillCompleted}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepLubricationSystemCheckFields.deviations}
          commentFieldName={MechanicalStepLubricationSystemCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
