import { useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'

export function useMission() {
  const { missionId } = useParams()

  const { data = [], isLoading: isMissionLoading } = apiCall.useMissionsControllerFindQuery([])

  let missionError = ''
  const mission = data.find((el) => el._id === missionId)

  if (!mission) {
    missionError = `Миссия с id ${missionId || 'unknown'} не найдена`
  }

  return { mission, isMissionLoading, missionError }
}
