import style from './confirmationModal.module.less'
import { Button } from '@smwb/summer-ui'
import { Title } from '@/components/ui/typography/title'
import { Text } from '@/components/ui/typography/text'
import cn from 'classnames'
import { ReactNode } from 'react'
import { Modal } from '@/components/ui/feedBack/modal/modal'

export interface ConfirmationModalProps {
  isOpen: boolean
  title: string
  content: ReactNode | string
  loading?: boolean
  noFooter?: boolean
  onAccept?: () => void
  onCancel: () => void
}

export function ConfirmationModal({
  isOpen,
  title,
  content,
  loading,
  noFooter,
  onAccept,
  onCancel
}: ConfirmationModalProps) {
  const isStringContent = typeof content === 'string'

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <Modal.Header className={style.modalHeader}>
        <Title level={1}>{title}</Title>
      </Modal.Header>

      <Modal.Content className={cn(style.modalContent, isStringContent && style.modalContentText)}>
        {isStringContent ? <Text size='l'>{content}</Text> : content}
      </Modal.Content>

      {!noFooter && (
        <Modal.Footer className={style.modalFooter}>
          <Button
            variant='outlined'
            type='button'
            label='Отмена'
            size='large'
            disabled={loading}
            onClick={onCancel}
          />

          <Button
            type='button'
            label='Подтвердить'
            size='large'
            disabled={loading}
            onClick={onAccept}
          />
        </Modal.Footer>
      )}
    </Modal>
  )
}
