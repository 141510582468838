import style from './index.module.less'
import { BaseReportFields, Groups, StepStatus } from '@modules/missions/keaz/types'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { skipToken } from '@reduxjs/toolkit/query'
import { PageTitle } from '@modules/missions/keaz/components/pageTitle'
import { Title } from '@/components/ui/typography/title'
import { Button, useSnackbar } from '@smwb/summer-ui'
import { Urls } from '@/urls'
import { PROJECT } from '@modules/missions/keaz/const'
import { getStepTitle } from '@modules/missions/keaz/utils/step'
import { RejectStepConfirmationModal } from '@modules/missions/keaz/step/components/rejectStepConfirmationModal'
import { ScreenContent } from '@modules/missions/keaz/step/components/ScreenContent'
import { isWaitingApprovalStep } from '@modules/missions/keaz/utils/steps'
import { Loader } from '@modules/common/loader'
import { Error } from '@modules/common/error'
import { useMission } from '@modules/missions/hooks/useMission'
import { capitalizeFirstLetter } from '@utils/text'

export function Index() {
  const { missionId, taskId, groupIndex, stepId } = useParams()

  const group: Groups | undefined = Object.values(Groups)[parseInt(groupIndex || '0')]
  const { data: step, isLoading } = apiCall.useAgentumReportControllerFindByIdQuery(
    missionId ? [missionId, PROJECT, stepId || ''] : skipToken
  )

  const { data: task } = apiCall.useAgentumReportControllerFindByIdQuery(
    missionId ? [missionId, PROJECT, taskId || ''] : skipToken
  )

  const canEditStep = false

  const [compact, setCompact] = useState(!canEditStep)
  const [rejectStepModalOpen, setRejectStepModalOpen] = useState(false)

  const navigate = useNavigate()

  interface UpdateReportByIdArgs {
    isLoading?: boolean
    error?: unknown
    data?: unknown
  }

  const onBack = useCallback(() => {
    navigate(
      generatePath(Urls.KeazGroup, {
        missionId: missionId || null,
        taskId: taskId || null,
        groupIndex: groupIndex || null
      }),
      {
        replace: true
      }
    )
  }, [groupIndex, taskId, missionId, navigate])

  const [
    updateReportById,
    {
      data: updateReportByIdData,
      isLoading: updateReportByIdIsLoading,
      error: updateReportByIdError
    }
  ] = apiCall.useAgentumReportControllerUpdateByIdMutation<UpdateReportByIdArgs>()

  const { addSnackbar } = useSnackbar()
  const addSnackbarRef = useRef(addSnackbar)

  const { mission, missionError } = useMission()

  useEffect(() => {
    if (mission && task && step && group) {
      document.title = `${mission.mission_name}. Задача №${String(
        task[BaseReportFields.name]
      )}. ${capitalizeFirstLetter(group)}. Шаг ${getStepTitle(step)}`
    }
  }, [mission, step, task, group])

  useEffect(() => {
    if (!updateReportByIdIsLoading) {
      if (updateReportByIdData !== undefined) {
        addSnackbarRef.current({
          message: 'Статус отчета изменен',
          position: 'top-right',
          variant: 'success'
        })

        onBack()
      } else if (updateReportByIdError) {
        addSnackbarRef.current({
          message: 'Ошибка изменения статуса отчета',
          position: 'top-right',
          variant: 'danger'
        })
      }
    }
  }, [updateReportByIdData, updateReportByIdIsLoading, updateReportByIdError, onBack])

  const handleRejectStep = (reason: string) => {
    if (missionId && step) {
      void updateReportById([
        missionId,
        PROJECT,
        step[BaseReportFields.id] as string,
        {
          [BaseReportFields.status]: StepStatus.rejected,
          [BaseReportFields.statusReason]: reason
        }
      ])
    }
  }

  const handleApproveStep = () => {
    if (missionId && step) {
      void updateReportById([
        missionId,
        PROJECT,
        step[BaseReportFields.id] as string,
        {
          [BaseReportFields.status]: StepStatus.approved
        }
      ])
    }
  }

  if (isLoading) {
    return <Loader />
  }

  if (missionError) {
    return <Error message={missionError} />
  }

  if (!task) {
    return <Error message={'Задача не найдена'} />
  }

  if (!group) {
    return <Error message={'Группа не найдена'} />
  }

  if (!step) {
    return <Error message={'Шаг не найден'} />
  }

  return (
    <>
      <PageTitle
        title={getStepTitle(step)}
        onBack={() =>
          navigate(
            generatePath(Urls.KeazGroup, {
              missionId: missionId || null,
              taskId: taskId || null,
              groupIndex: groupIndex || null
            }),
            {
              replace: true
            }
          )
        }
      />

      <div className={style.card}>
        <Title level={1}>Данные</Title>

        <ScreenContent
          step={step}
          compact={compact}
          setCompact={setCompact}
          isEditableStep={!!canEditStep}
          onBackClick={onBack}
          refetchReports={() => ({})}
        />
      </div>

      {isWaitingApprovalStep(step) && (
        <div className={style.buttons}>
          <Button
            variant='outlined'
            disabled={updateReportByIdIsLoading}
            onClick={() => setRejectStepModalOpen(true)}
          >
            Отклонить
          </Button>

          <Button disabled={updateReportByIdIsLoading} onClick={handleApproveStep}>
            Принять
          </Button>
        </div>
      )}

      <RejectStepConfirmationModal
        isOpen={rejectStepModalOpen}
        loading={updateReportByIdIsLoading}
        onAccept={handleRejectStep}
        onCancel={() => setRejectStepModalOpen(false)}
      />
    </>
  )
}
