import { useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { PROJECT } from '@modules/missions/keaz/const'
import { BaseReportFields, GroupStatus, ReportSubtype } from '@modules/missions/keaz/types'
import { skipToken } from '@reduxjs/toolkit/query'
import { getStepsSummaryStatus } from '@modules/missions/keaz/utils/steps'
import { AgentumReportWithRelations } from '@api/generated'

interface TasksTableCellStatusProps {
  task: AgentumReportWithRelations
}

const getSummaryStatusColor = (status: GroupStatus) => {
  let color = 'black'

  switch (status) {
    case GroupStatus.signed:
      color = 'gray2'
      break
    case GroupStatus.default:
      color = 'gray5'
      break
    case GroupStatus.approved:
    case GroupStatus.sendedToERP:
      color = 'success'
      break
    case GroupStatus.inProgress:
      color = 'warning'
      break
    case GroupStatus.rejected:
      color = 'orange'
      break
  }

  return `var(--agm-color-${color})`
}

export function TasksTableCellStatus({ task }: TasksTableCellStatusProps) {
  const { missionId } = useParams()
  const { data: steps = [] } = apiCall.useAgentumReportControllerFindQuery(
    missionId
      ? [
          missionId,
          PROJECT,
          {
            where: {
              [BaseReportFields.subtype]: ReportSubtype.step,
              [BaseReportFields.taskId]: task[BaseReportFields.origin],
              [BaseReportFields.deleted]: false
            }
          }
        ]
      : skipToken
  )

  const summaryStatus = getStepsSummaryStatus(steps, task)

  return (
    <span style={{ color: getSummaryStatusColor(summaryStatus) }}>
      {steps.length ? summaryStatus : ''}
    </span>
  )
}
