import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  ElectricalStepElectricalConnectionsCheck,
  ElectricalStepElectricalConnectionsCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

import { ErrorMessage } from '@modules/missions/keaz/step/components/contentBySteps/components/errorMessage'

export interface ElectricalConnectionsCheckProps {
  compact: boolean
  step: ElectricalStepElectricalConnectionsCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function ElectricalConnectionsCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: ElectricalConnectionsCheckProps) {
  return (
    <div>
      <StepForm<ElectricalStepElectricalConnectionsCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={ElectricalStepElectricalConnectionsCheckFields.circuitBreakerTightnessCheck}
              label='Проверка затяжки автоматов'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={
                ElectricalStepElectricalConnectionsCheckFields.circuitBreakerTightnessCheck
              }
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={ElectricalStepElectricalConnectionsCheckFields.contactorTightnessCheck}
              label='Проверка затяжки контактора на нагрев цилиндра'
              disabled={compact}
            />

            <ErrorMessage
              fieldName={ElectricalStepElectricalConnectionsCheckFields.contactorTightnessCheck}
              className={styles.errorMessage}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={ElectricalStepElectricalConnectionsCheckFields.deviations}
          commentFieldName={ElectricalStepElectricalConnectionsCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
