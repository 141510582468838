import style from './rejectStepConfirmationModal.module.less'
import { Button } from '@smwb/summer-ui'
import { Form } from 'react-final-form'
import { yupValidate } from '@utils/yupValidate'
import * as yup from 'yup'
import { TextArea } from '@/components/ui/connects/rff'
import { ConfirmationModal } from '@modules/common/modals/confirmationModal'

export interface CancelStepModalProps {
  isOpen: boolean
  loading?: boolean
  onAccept: (reason: string) => void
  onCancel: () => void
}

const schema = yup.object().shape({
  reason: yup.string().required('Комментарий обязательное поле')
})

const validate = yupValidate(schema)

export function RejectStepConfirmationModal({
  isOpen,
  loading,
  onAccept,
  onCancel
}: CancelStepModalProps) {
  const onSubmit = (values: { reason: string }) => {
    void onAccept(values.reason)
  }

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title='Укажите причину отклонения объекта'
      noFooter
      content={
        <Form
          validate={validate}
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form>
              <TextArea
                label='Комментарий'
                name='reason'
                rows={5}
                fullWidth
                placeholder='Опишите подробно причину, ошибки и пожелания'
              />

              <div className={style.buttons}>
                <Button
                  variant='outlined'
                  type='button'
                  label='Отмена'
                  size='large'
                  disabled={loading}
                  onClick={onCancel}
                />

                {/* eslint-disable @typescript-eslint/no-misused-promises */}
                <Button
                  type='button'
                  label='Подтвердить'
                  size='large'
                  disabled={loading}
                  onClick={handleSubmit}
                />
                {/* eslint-enable @typescript-eslint/no-misused-promises */}
              </div>
            </form>
          )}
        />
      }
      onCancel={onCancel}
    />
  )
}
