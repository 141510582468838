import React, { memo, useMemo } from 'react'
import { screenContent } from '@modules/missions/keaz/step/const'
import { BaseReportFields, Groups } from '@modules/missions/keaz/types'
import { AgentumReportWithRelations } from '@api/generated'

interface ScreenContentProps {
  step: AgentumReportWithRelations
  compact: boolean
  setCompact: (value: boolean) => void
  isEditableStep: boolean
  onBackClick: () => void
  refetchReports: () => void
}

export const ScreenContent = memo(function ({
  compact,
  setCompact,
  step,
  isEditableStep,
  onBackClick,
  refetchReports
}: ScreenContentProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Component = useMemo<React.ComponentType<any> | null>(
    () =>
      screenContent[step[BaseReportFields.group] as keyof typeof screenContent][
        step[BaseReportFields.stepType] as keyof (typeof screenContent)[Groups]
      ],
    [step]
  )

  if (!Component) {
    return null
  }

  return (
    <Component
      compact={compact}
      step={step}
      isEditableStep={isEditableStep}
      setCompact={setCompact}
      onBackClick={onBackClick}
      refetchReports={refetchReports}
    />
  )
})
