import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { Checkbox, TextField } from '@smwb/summer-ui/dist/connects/rff'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'

export interface DeviationsFormFieldProps {
  compact: boolean
  detectedFieldName: string
  commentFieldName: string
  className?: string
}

export function DeviationsFormFields({
  compact,
  detectedFieldName,
  commentFieldName,
  className
}: DeviationsFormFieldProps) {
  return (
    <div className={className}>
      <FormGroup compact={compact}>
        <Card className={styles.card}>
          <Checkbox name={detectedFieldName} label='Обнаружены отклонения' disabled={compact} />
        </Card>
      </FormGroup>

      <FormGroup compact={compact}>
        <Card className={styles.card}>
          <TextField
            name={commentFieldName}
            label='Комментарии по отклонениям'
            fullWidth={true}
            disabled={compact}
          />
        </Card>
      </FormGroup>
    </div>
  )
}
