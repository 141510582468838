import { Table } from '@smwb/summer-ui/dist/connects/rt'
import { ColumnDef } from '@tanstack/react-table'
import { AgentumReportWithRelations } from '@api/generated'
import { BaseReportFields } from '@modules/missions/keaz/types'
import { TasksTableCellActions } from '@modules/missions/keaz/components/tasksTableCellActions'
import { TasksTableCellStatus } from '@modules/missions/keaz/components/tasksTableCellStatus'
import { TasksTableCellCompletedSteps } from '@modules/missions/keaz/components/tasksTableCellCompletedSteps'
import { TasksTableCellApprovedSteps } from '@modules/missions/keaz/components/tasksTableCellApprovedSteps'
import style from './tasksTable.module.less'
import { TasksTableCellAssignees } from '@modules/missions/keaz/components/tasksTableCellAssignees'
import { TasksTableCellResponsibleUser } from '@modules/missions/keaz/components/tasksTableCellResponsibleUser'

interface TasksTableProps {
  tasks: AgentumReportWithRelations[]
  isLoading: boolean
  handleRowClick: (task: AgentumReportWithRelations) => void
}

const columns: ColumnDef<AgentumReportWithRelations>[] = [
  {
    header: 'Номер задачи',
    accessorKey: BaseReportFields.name
  },
  {
    header: 'Ответственный',
    cell: ({ row }) => <TasksTableCellResponsibleUser task={row.original} />
  },
  {
    header: 'Наименование оборудования',
    accessorKey: BaseReportFields.equipment
  },
  {
    header: 'Исполнитель',
    cell: ({ row }) => <TasksTableCellAssignees task={row.original} />
  },
  {
    header: 'Статус',
    cell: ({ row }) => <TasksTableCellStatus task={row.original} />
  },
  {
    header: 'Выполнено шагов',
    cell: ({ row }) => <TasksTableCellCompletedSteps task={row.original} />
  },
  {
    header: 'Проверено шагов',
    cell: ({ row }) => <TasksTableCellApprovedSteps task={row.original} />
  },
  {
    header: '',
    accessorKey: 'actions',
    cell: ({ row }) => <TasksTableCellActions task={row.original} />
  }
]

export function TasksTable({ tasks, isLoading, handleRowClick }: TasksTableProps) {
  return (
    <Table
      data={tasks}
      columns={columns}
      isLoading={isLoading}
      withPagination
      limit={10}
      limitOptions={[10, 20, 30]}
      onBodyRowClick={({ original }) => handleRowClick(original)}
      className={style.table}
    />
  )
}
