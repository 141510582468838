import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import { Checkbox } from '@smwb/summer-ui/dist/connects/rff'
import {
  MechanicalStepHydraulicConnectionCheck,
  MechanicalStepHydraulicConnectionCheckFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'
import React from 'react'

export interface HydraulicConnectionCheckProps {
  compact: boolean
  step: MechanicalStepHydraulicConnectionCheck
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function HydraulicConnectionCheck({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: HydraulicConnectionCheckProps) {
  return (
    <div>
      <StepForm<MechanicalStepHydraulicConnectionCheck>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepHydraulicConnectionCheckFields.rearToggleMechanismCheckPoint1}
              label='Проверка на течь сзади колено-рычажного механизма в точке №1 проведена'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepHydraulicConnectionCheck>
              compact={compact}
              title='Фото сзади колено-рычажного механизма в точке №1'
              fieldName={
                MechanicalStepHydraulicConnectionCheckFields.rearToggleMechanismPhotoPoint1
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepHydraulicConnectionCheckFields.rearToggleMechanismCheckPoint2}
              label='Проверка на течь сзади колено-рычажного механизма в точке №2 проведена'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepHydraulicConnectionCheck>
              compact={compact}
              title='Фото сзади колено-рычажного механизма в точке №2'
              fieldName={
                MechanicalStepHydraulicConnectionCheckFields.rearToggleMechanismPhotoPoint2
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepHydraulicConnectionCheckFields.rearPlasticizationUnitCheckPoint1}
              label='Проверка на течь сзади узла пластикации в точке №1 проведена'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepHydraulicConnectionCheck>
              compact={compact}
              title='Фото сзади узла пластикации в точке №1'
              fieldName={
                MechanicalStepHydraulicConnectionCheckFields.rearPlasticizationUnitPhotoPoint1
              }
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <Checkbox
              name={MechanicalStepHydraulicConnectionCheckFields.rearPlasticizationUnitCheckPoint2}
              label='Проверка на течь сзади узла пластикации в точке №2 проведена'
              disabled={compact}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepHydraulicConnectionCheck>
              compact={compact}
              title='Фото сзади узла пластикации в точке №2'
              fieldName={
                MechanicalStepHydraulicConnectionCheckFields.rearPlasticizationUnitPhotoPoint2
              }
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepHydraulicConnectionCheckFields.deviations}
          commentFieldName={MechanicalStepHydraulicConnectionCheckFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
