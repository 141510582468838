import {
  MechanicalStepMachineIdentification,
  MechanicalStepMachineIdentificationFields
} from '@modules/missions/keaz/types'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'
import { QrScannerCard } from '@modules/missions/keaz/step/components/contentBySteps/components/qrScannerCard'

export interface MachineIdentificationProps {
  compact: boolean
  step: MechanicalStepMachineIdentification
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function MachineIdentificationContent({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: MachineIdentificationProps) {
  return (
    <div>
      <StepForm<MechanicalStepMachineIdentification>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <QrScannerCard
            fieldName={MechanicalStepMachineIdentificationFields.qrCode}
            compact={compact}
          />
        </FormGroup>
      </StepForm>
    </div>
  )
}
