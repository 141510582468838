import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Asigner, BaseReportFields, StepStatus } from '@modules/missions/keaz/types'
import { InProgressTime } from '@modules/missions/keaz/group/components/inProgressTime'
import { secondsToHHMMSS } from '@modules/missions/keaz/utils/time'
import { AgentumReportWithRelations } from '@api/generated'
import { getStepTitle } from '@modules/missions/keaz/utils/step'
import { ColumnDef } from '@tanstack/react-table'
import { Table } from '@smwb/summer-ui/dist/connects/rt'
import { StepsTableActionsCell } from '@modules/missions/keaz/group/components/stepsTableActionsCell'
import { Checkbox, Button, useSnackbar } from '@smwb/summer-ui'
import { apiCall, UpdateReportsByIdsResponse } from '@api/resources/apiCall'
import { PROJECT } from '@modules/missions/keaz/const'
import { useParams } from 'react-router-dom'
import { formatFullName } from '@modules/missions/keaz/components/formatFullName'
import { ConfirmationModal } from '@modules/common/modals/confirmationModal'
import style from './stepsTable.module.less'
import { isWaitingApprovalStep } from '@modules/missions/keaz/utils/steps'

enum StepAction {
  accept = 'accept',
  reject = 'reject'
}

interface StepsTableProps {
  steps: AgentumReportWithRelations[]
  isLoading: boolean
}

const getStepStatusColor = (status: StepStatus) => {
  let color = 'black'

  switch (status) {
    case StepStatus.waitingApproval:
      color = 'gray2'
      break
    case StepStatus.default:
      color = 'gray5'
      break
    case StepStatus.approved:
      color = 'success'
      break
    case StepStatus.inProgress:
      color = 'warning'
      break
    case StepStatus.rejected:
      color = 'orange'
      break
  }

  return `var(--agm-color-${color})`
}

export function StepsTable({ steps, isLoading }: StepsTableProps) {
  const { missionId } = useParams()

  const [selectedSteps, setSelectedSteps] = useState<string[]>([])
  const [selectedAction, setSelectedAction] = useState<StepAction>()
  const [isModalOpen, setModalOpen] = useState(false)

  const waitingApprovalSteps = steps.filter((step) => isWaitingApprovalStep(step))

  const isAllSelected = selectedSteps.length === waitingApprovalSteps.length

  const toggleSelectAll = useCallback(() => {
    if (isAllSelected) {
      setSelectedSteps([])
    } else {
      setSelectedSteps(waitingApprovalSteps.map((step) => step[BaseReportFields.id] as string))
    }
  }, [isAllSelected, waitingApprovalSteps])

  const toggleStepSelection = useCallback(
    (id: string) => {
      if (selectedSteps.includes(id)) {
        setSelectedSteps((selectedSteps) => selectedSteps.filter((stepId) => stepId !== id))
      } else {
        setSelectedSteps((selectedSteps) => [...selectedSteps, id])
      }
    },
    [selectedSteps]
  )

  const handleActionClick = (action: StepAction) => {
    setSelectedAction(action)
    setModalOpen(true)
  }

  const [
    updateReportsByIds,
    { isLoading: updateReportsByIdsLoading, error: updateReportsByIdsErrors }
  ] = apiCall.useUpdateReportsByIdsMutation<UpdateReportsByIdsResponse>()

  const { addSnackbar } = useSnackbar()
  const addSnackbarRef = useRef(addSnackbar)
  const updateReportRef = useRef(false)

  useEffect(() => {
    if (updateReportsByIdsLoading) {
      updateReportRef.current = true
      return
    }

    if (!updateReportRef.current) {
      return
    }

    if (updateReportsByIdsErrors) {
      setSelectedSteps((selectedSteps) => {
        return selectedSteps.filter((stepId) => updateReportsByIdsErrors.has(stepId))
      })

      setModalOpen(false)

      addSnackbarRef.current({
        message: 'Ошибка изменения статуса у части шагов',
        position: 'top-right',
        variant: 'danger'
      })
    } else {
      addSnackbarRef.current({
        message: 'Статус шагов изменен',
        position: 'top-right',
        variant: 'success'
      })

      setSelectedSteps([])
      setModalOpen(false)
    }
  }, [updateReportsByIdsErrors, updateReportsByIdsLoading])

  const confirmAction = () => {
    if (!selectedAction) return

    // Обновляем статус выбранных шагов на сервере
    if (missionId) {
      void updateReportsByIds([
        missionId,
        PROJECT,
        selectedSteps,
        {
          [BaseReportFields.status]:
            selectedAction === StepAction.accept ? StepStatus.approved : StepStatus.rejected
        }
      ])
    }
  }

  const columns = useMemo(() => {
    const columns: ColumnDef<AgentumReportWithRelations>[] = []

    if (waitingApprovalSteps.length) {
      columns.push({
        header: () => (
          <Checkbox checked={isAllSelected} onChange={toggleSelectAll} crossOrigin={undefined} />
        ),
        accessorKey: 'select',
        cell: ({ row }) =>
          isWaitingApprovalStep(row.original) && (
            <Checkbox
              checked={selectedSteps.includes(row.original[BaseReportFields.id] as string)}
              onChange={() => toggleStepSelection(row.original[BaseReportFields.id] as string)}
              crossOrigin={undefined}
            />
          )
      })
    }

    columns.push(
      ...([
        {
          header: 'Название шага',
          cell: ({ row }) => <div className={style.stepNameCell}>{getStepTitle(row.original)}</div>
        },
        {
          header: 'Планируемое время выполнения',
          cell: ({ row }) => (
            <>{secondsToHHMMSS((row.original[BaseReportFields.timeNorm] as number[]) || [])}</>
          )
        },
        {
          header: 'Фактическое время выполнения',
          cell: ({ row }) => <>{<InProgressTime step={row.original} />}</>
        },
        {
          header: 'Исполнитель',
          cell: ({ row }) => (
            <>
              {row.original[BaseReportFields.assigner]
                ? formatFullName(
                    (row.original[BaseReportFields.assigner] as Asigner).firstname,
                    (row.original[BaseReportFields.assigner] as Asigner).lastname
                  )
                : ''}
            </>
          )
        },
        {
          header: 'Статус',
          cell: ({ row }) => (
            <span
              style={{
                color: getStepStatusColor(row.original[BaseReportFields.status] as StepStatus)
              }}
            >
              {row.original[BaseReportFields.status]}
            </span>
          )
        },
        {
          header: '',
          accessorKey: 'actions',
          cell: ({ row }) => <StepsTableActionsCell step={row.original} />
        }
      ] as ColumnDef<AgentumReportWithRelations>[])
    )

    return columns
  }, [
    isAllSelected,
    selectedSteps,
    toggleSelectAll,
    toggleStepSelection,
    waitingApprovalSteps.length
  ])

  return (
    <div>
      <Table data={steps} columns={columns} isLoading={isLoading} className={style.table} />

      {!!selectedSteps.length && (
        <div className={style.buttons}>
          <Button
            onClick={() => handleActionClick(StepAction.accept)}
            disabled={updateReportsByIdsLoading}
          >
            Принять
          </Button>

          <Button
            variant='outlined'
            onClick={() => handleActionClick(StepAction.reject)}
            disabled={updateReportsByIdsLoading}
          >
            Отклонить
          </Button>
        </div>
      )}

      <ConfirmationModal
        isOpen={isModalOpen}
        title='Подтверждение'
        content={`Вы уверены, что хотите ${
          selectedAction === StepAction.accept ? 'принять' : 'отклонить'
        } выбранные шаги?`}
        loading={updateReportsByIdsLoading}
        onAccept={confirmAction}
        onCancel={() => setModalOpen(false)}
      />
    </div>
  )
}
