export enum Urls {
  Root = '/',
  Login = '/login',
  Missions = '/missions',
  Mission = '/missions/:missionId',
  MissionFile = `/missions/:missionId/projects/:project/files/:file`,
  KeazTask = '/missions/:missionId/task/:taskId',
  KeazGroup = '/missions/:missionId/task/:taskId/group/:groupIndex',
  KeazStep = '/missions/:missionId/task/:taskId/group/:groupIndex/step/:stepId'
}
