import { MissionType } from '@modules/missions/const'
import { PotokFmMission } from '@modules/missions/potok-fm'
import { KeazMission } from '@modules/missions/keaz'
import { useMission } from '@modules/missions/hooks/useMission'
import { Loader } from '@modules/common/loader'
import { Error } from '@modules/common/error'
import { ReactNode, useEffect } from 'react'
import { MissionsWithRelations } from '@api/generated'

type PageData = Record<MissionType, { title: string; component: ReactNode }>

const getPageData = (mission?: MissionsWithRelations) => {
  if (!mission) {
    return undefined
  }

  const pageData: PageData = {
    [MissionType.potokFM]: {
      title: mission.mission_name,
      component: <PotokFmMission />
    },
    [MissionType.keaz]: {
      title: `${mission.mission_name}. Список задач`,
      component: <KeazMission />
    }
  }

  return mission.mission in pageData ? pageData[mission.mission as MissionType] : undefined
}

export function Landing() {
  const { mission, isMissionLoading, missionError } = useMission()

  const pageData = getPageData(mission)

  useEffect(() => {
    if (pageData) {
      document.title = pageData.title
    }
  }, [pageData])

  if (isMissionLoading) {
    return <Loader />
  }

  if (!mission) {
    return <Error message={missionError} />
  }

  if (pageData) {
    return <>{pageData.component}</>
  }

  return (
    <div>
      <div>Шаблон для миссия не найден</div>
      <pre>{JSON.stringify(mission, undefined, 4)}</pre>
    </div>
  )
}
