import baseStyle from '../task/index.module.less'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { BaseReportFields, Groups, ReportSubtype } from '@modules/missions/keaz/types'
import { skipToken } from '@reduxjs/toolkit/query'
import { PageTitle } from '@modules/missions/keaz/components/pageTitle'
import { StepsTable } from '@modules/missions/keaz/group/components/stepsTable'
import { Urls } from '@/urls'
import { PROJECT } from '@modules/missions/keaz/const'
import { useEffect } from 'react'
import { useMission } from '@modules/missions/hooks/useMission'
import { Loader } from '@modules/common/loader'
import { Error } from '@modules/common/error'
import { capitalizeFirstLetter } from '@utils/text'

export function Index() {
  const { missionId, taskId, groupIndex } = useParams()

  const { data: task } = apiCall.useAgentumReportControllerFindByIdQuery(
    missionId ? [missionId, PROJECT, taskId || ''] : skipToken
  )

  const group: Groups | undefined = Object.values(Groups)[parseInt(groupIndex || '0')]
  const { data: steps = [], isLoading } = apiCall.useAgentumReportControllerFindQuery(
    missionId && task
      ? [
          missionId,
          PROJECT,
          {
            where: {
              [BaseReportFields.subtype]: ReportSubtype.step,
              [BaseReportFields.taskId]: task && task[BaseReportFields.origin],
              [BaseReportFields.group]: group,
              [BaseReportFields.deleted]: false
            },
            order: ['stepNumber ASC']
          }
        ]
      : skipToken
  )

  const { mission, missionError } = useMission()

  const navigate = useNavigate()

  const title = `${
    task ? `Задача №${String(task[BaseReportFields.name])}. ` : ''
  }${capitalizeFirstLetter(group || '')}`

  useEffect(() => {
    if (mission) {
      document.title = `${mission.mission_name}. ${title}`
    }
  }, [mission, title])

  if (isLoading) {
    return <Loader />
  }

  if (missionError) {
    return <Error message={missionError} />
  }

  if (!task) {
    return <Error message={'Задача не найдена'} />
  }

  if (!group) {
    return <Error message={'Группа не найдена'} />
  }

  return (
    <>
      <PageTitle
        title={title}
        onBack={() =>
          navigate(
            generatePath(Urls.KeazTask, { missionId: missionId || null, taskId: taskId || null }),
            {
              replace: true
            }
          )
        }
      />

      <div className={baseStyle.section}>
        <StepsTable steps={steps} isLoading={isLoading} />
      </div>
    </>
  )
}
