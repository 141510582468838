import { useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { PROJECT } from '@modules/missions/keaz/const'
import { BaseReportFields, ReportSubtype } from '@modules/missions/keaz/types'
import { skipToken } from '@reduxjs/toolkit/query'
import { isCompletedStep } from '@modules/missions/keaz/utils/steps'
import { AgentumReportWithRelations } from '@api/generated'

interface TasksTableCellCompletedStepsProps {
  task: AgentumReportWithRelations
}

export function TasksTableCellCompletedSteps({ task }: TasksTableCellCompletedStepsProps) {
  const { missionId } = useParams()
  const { data: steps = [] } = apiCall.useAgentumReportControllerFindQuery(
    missionId
      ? [
          missionId,
          PROJECT,
          {
            where: {
              [BaseReportFields.subtype]: ReportSubtype.step,
              [BaseReportFields.taskId]: task[BaseReportFields.origin],
              [BaseReportFields.deleted]: false
            }
          }
        ]
      : skipToken
  )

  const completedSteps = steps.reduce((count: number, step) => count + +isCompletedStep(step), 0)

  return <>{`${completedSteps}/${steps.length}`}</>
}
