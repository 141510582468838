import styles from '../index.module.less'
import { Card } from '@modules/missions/keaz/step/components/card'
import { PhotoView } from '@modules/missions/keaz/step/components/photoView'
import { FormGroup } from '@modules/missions/keaz/step/components/formGroup'
import {
  MechanicalStepEquipmentCleaning,
  MechanicalStepEquipmentCleaningFields
} from '@modules/missions/keaz/types'
import { DeviationsFormFields } from '@modules/missions/keaz/step/components/contentBySteps/components/deviationsFormFields'
import React from 'react'
import { StepForm } from '@modules/missions/keaz/step/components/stepForm'

export interface EquipmentCleaningProps {
  compact: boolean
  step: MechanicalStepEquipmentCleaning
  isEditableStep: boolean
  setCompact: React.Dispatch<React.SetStateAction<boolean>>
  onBackClick: () => void
  refetchReports: () => void
}

export function EquipmentCleaning({
  compact,
  step,
  isEditableStep,
  setCompact,
  onBackClick,
  refetchReports
}: EquipmentCleaningProps) {
  return (
    <div>
      <StepForm<MechanicalStepEquipmentCleaning>
        step={step}
        compact={compact}
        isEditableStep={isEditableStep}
        setCompact={setCompact}
        onBackClick={onBackClick}
        refetchReports={refetchReports}
      >
        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepEquipmentCleaning>
              compact={compact}
              title='Фото очищенного масляного поддона'
              fieldName={MechanicalStepEquipmentCleaningFields.cleanedOilPanPhoto}
            />
          </Card>
        </FormGroup>

        <FormGroup compact={compact}>
          <Card className={styles.card}>
            <PhotoView<MechanicalStepEquipmentCleaning>
              compact={compact}
              title='Фото очищенных направляющих колено-рычажного механизма'
              fieldName={MechanicalStepEquipmentCleaningFields.cleanedToggleGuidesPhoto}
            />
          </Card>
        </FormGroup>

        <DeviationsFormFields
          compact={compact}
          detectedFieldName={MechanicalStepEquipmentCleaningFields.deviations}
          commentFieldName={MechanicalStepEquipmentCleaningFields.deviationsComment}
          className={styles.deviationsFields}
        />
      </StepForm>
    </div>
  )
}
