export function formatFullName(firstname = '', lastname = '') {
  let firstInitial = ''
  let middleInitial = ''

  if (typeof firstname === 'string') {
    const [firstName, middleName] = firstname.split(' ')
    firstInitial = firstName ? `${firstName[0]}.` : ''
    middleInitial = middleName ? `${middleName[0]}.` : ''
  }

  return `${firstInitial} ${middleInitial} ${lastname}`
}
