import style from './modal.module.less'
import { Modal as SmwbModal } from '@smwb/summer-ui'
import { Icon } from '@/components/ui/base/icon/icon'
import { ReactNode } from 'react'

export interface ModalProps {
  isOpen: boolean
  children: ReactNode
  onClose: () => void
}

export function Modal({ isOpen, children, onClose }: ModalProps) {
  return (
    <SmwbModal
      animation={'fade'}
      asForm={false}
      backdrop='dark'
      className={style.modal}
      closeOnOutsideClick={true}
      onClose={onClose}
      open={isOpen}
      size='large'
      disableAppearAnimation={false}
    >
      <div className={style.closeButton} onClick={onClose}>
        <Icon icon='close' />
      </div>

      {children}
    </SmwbModal>
  )
}

Modal.Header = SmwbModal.Header
Modal.Content = SmwbModal.Content
Modal.Footer = SmwbModal.Footer
