import { ElectricalSteps, Groups, MechanicalSteps } from '@modules/missions/keaz/types'
import { MachineIdentificationContent as MechanicalMachineIdentificationContent } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/machineIdentificationContent'
import { HydraulicConnectionCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/hydraulicConnectionCheck'
import { PressureAndPumpCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/pressureAndPumpCheck'
import { SafetyCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/safetyCheck'
import { LimitSwitchCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/limitSwitchCheck'
import { InjectionUnitLeakCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/injectionUnitLeakCheck'
import { ClampingUnitLeakCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/clampingUnitLeakCheck'
import { EjectionUnitLeakCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/ejectionUnitLeakCheck'
import { FasteningCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/fasteningCheck'
import { CoolingSystemCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/coolingSystemCheck'
import { CoolingCircuitFlush } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/coolingCircuitFlush'
import { OilChangeAndFilterCleaning } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/oilChangeAndFilterCleaning'
import { FineOilFilterCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/fineOilFilterCheck'
import { LubricationSystemCheck } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/lubricationSystemCheck'
import { EquipmentCleaning } from '@modules/missions/keaz/step/components/contentBySteps/mechanical/equipmentCleaning'

import { MachineIdentificationContent as ElectricalMachineIdentificationContent } from '@modules/missions/keaz/step/components/contentBySteps/electrical/machineIdentificationContent'
import { CurrentCheckUnderLoad } from '@modules/missions/keaz/step/components/contentBySteps/electrical/currentCheckUnderLoad'
import { NoiseAndVibrationCheck } from '@modules/missions/keaz/step/components/contentBySteps/electrical/noiseAndVibrationCheck'
import { CabinetAndMotorCleaning } from '@modules/missions/keaz/step/components/contentBySteps/electrical/cabinetAndMotorCleaning'
import { HeaterZonesCheck } from '@modules/missions/keaz/step/components/contentBySteps/electrical/heaterZonesCheck'
import { ElectricalConnectionsCheck } from '@modules/missions/keaz/step/components/contentBySteps/electrical/electricalConnectionsCheck'
import { InjectionMoldingMachineMovementsCheck } from '@modules/missions/keaz/step/components/contentBySteps/electrical/injectionMoldingMachineMovementsCheck'

export const screenContent = {
  [Groups.mechanical]: {
    [MechanicalSteps.machineIdentification]: MechanicalMachineIdentificationContent,
    [MechanicalSteps.hydraulicConnectionCheck]: HydraulicConnectionCheck,
    [MechanicalSteps.pressureAndPumpCheck]: PressureAndPumpCheck,
    [MechanicalSteps.safetyCheck]: SafetyCheck,
    [MechanicalSteps.limitSwitchCheck]: LimitSwitchCheck,
    [MechanicalSteps.injectionUnitLeakCheck]: InjectionUnitLeakCheck,
    [MechanicalSteps.clampingUnitLeakCheck]: ClampingUnitLeakCheck,
    [MechanicalSteps.ejectionUnitLeakCheck]: EjectionUnitLeakCheck,
    [MechanicalSteps.fasteningCheck]: FasteningCheck,
    [MechanicalSteps.coolingSystemCheck]: CoolingSystemCheck,
    [MechanicalSteps.coolingCircuitFlush]: CoolingCircuitFlush,
    [MechanicalSteps.oilChangeAndFilterCleaning]: OilChangeAndFilterCleaning,
    [MechanicalSteps.fineOilFilterCheck]: FineOilFilterCheck,
    [MechanicalSteps.lubricationSystemCheck]: LubricationSystemCheck,
    [MechanicalSteps.equipmentCleaning]: EquipmentCleaning
  },
  [Groups.electrical]: {
    [ElectricalSteps.machineIdentification]: ElectricalMachineIdentificationContent,
    [ElectricalSteps.currentCheckUnderLoad]: CurrentCheckUnderLoad,
    [ElectricalSteps.noiseAndVibrationCheck]: NoiseAndVibrationCheck,
    [ElectricalSteps.cabinetAndMotorCleaning]: CabinetAndMotorCleaning,
    [ElectricalSteps.heaterZonesCheck]: HeaterZonesCheck,
    [ElectricalSteps.electricalConnectionsCheck]: ElectricalConnectionsCheck,
    [ElectricalSteps.injectionMoldingMachineMovementsCheck]: InjectionMoldingMachineMovementsCheck
  }
}
