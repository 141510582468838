import React from 'react'
import style from './image.module.less'
import { useParams } from 'react-router-dom'
import { config } from '@/settings'

export function File() {
  const { missionId, project, file } = useParams()

  return (
    <img
      src={`${config.apiUrl}/mission/${missionId || ''}/project/${project || ''}/files/${
        file || ''
      }`}
      alt=''
      className={style.img}
    />
  )
}
