import baseStyle from '../index.module.less'
import style from './index.module.less'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { apiCall } from '@api/resources/apiCall'
import { BaseReportFields, Groups } from '@modules/missions/keaz/types'
import { skipToken } from '@reduxjs/toolkit/query'
import { PageTitle } from '@modules/missions/keaz/components/pageTitle'
import cn from 'classnames'
import { GroupCard } from '@modules/missions/keaz/task/components/groupCard'
import { Urls } from '@/urls'
import { PROJECT } from '@modules/missions/keaz/const'
import { Assignee } from '@modules/missions/keaz/task/components/assignee'
import { useMission } from '@modules/missions/hooks/useMission'
import { Loader } from '@modules/common/loader'
import { Error } from '@modules/common/error'
import { useEffect } from 'react'

export function Index() {
  const { missionId, taskId } = useParams()
  const { data: task, isLoading } = apiCall.useAgentumReportControllerFindByIdQuery(
    missionId ? [missionId, PROJECT, taskId || ''] : skipToken
  )

  const { mission, missionError } = useMission()

  const navigate = useNavigate()

  const title = task ? `Задача №${String(task[BaseReportFields.name])}` : ''

  useEffect(() => {
    if (mission) {
      document.title = `${mission.mission_name}. ${title}`
    }
  }, [mission, title])

  if (isLoading) {
    return <Loader />
  }

  if (missionError || !task) {
    return <Error message={missionError || 'Задача не найдена'} />
  }

  return (
    <>
      <PageTitle
        title={title}
        onBack={() =>
          navigate(generatePath(Urls.Mission, { missionId: missionId || null }), {
            replace: true
          })
        }
      />

      <Assignee task={task} className={cn(baseStyle.section, style.assignee)} />

      <div className={cn(baseStyle.section, style.groups)}>
        {Object.values(Groups).map((group, index) => (
          <GroupCard
            key={index}
            task={task}
            group={group}
            onButtonClick={() =>
              navigate(
                generatePath(Urls.KeazGroup, {
                  missionId: missionId || null,
                  taskId: task[BaseReportFields.id] || null,
                  groupIndex: index.toString()
                })
              )
            }
          />
        ))}
      </div>
    </>
  )
}
